<template>
    <div>
        <v-card flat style="background: transparent !important" class="my-0 py-0">
            <v-card-text style="background: transparent !important" class="my-0 py-0">
                <v-tabs show-arrows v-model="activeTab" height="40px" class="mb-1 pb-1 mt-0 pt-0">
                    <v-tab v-for="tab in tabs" :key="tab" :href="`#${tab}`">
                        <v-chip class="mr-1" small v-if="tabMetric(tab)">{{ tabMetric(tab) }}</v-chip> {{ tab }}
                    </v-tab>
                </v-tabs>
                <!-- <v-tabs-items v-model="activeTab" class="my-0 py-0">
                    <v-tab-item>
                        <v-card flat class="my-0 py-0">
                            <v-card-text class="my-0 py-0">
                                <v-row justify="end">
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field outlined dense label="Search" clearable v-model="search"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-data-table @click:row="viewTask" hide-default-footer disable-pagination height="55vh" :search="search"
                                    :loading="loading" style="cursor: pointer" fixed-header :items="tasks.data"
                                    :headers="headers">
                                    <template v-slot:[`item.title`]="{ item }">
                                        <span>{{ item.title }}</span>
                                    </template>
                                    <template v-slot:[`item.status`]="{ item }">
                                        <v-chip :color="getTaskStatus(item).color" outlined small><v-icon small left>{{
                                            getTaskStatus(item).icon
                                        }}</v-icon>
                                            {{ item.status }}</v-chip>
                                    </template>
                                    <template v-slot:[`item.submittedBy`]="{ item }">
                                        <span>{{ item.submittedForInspectionBy.firstName }} {{
                                            item.submittedForInspectionBy.surname
                                        }}</span> <br>
                                        <span>{{ formatDate(item.createdAt) }}</span>
                                    </template>
                                    <template v-slot:[`item.shipment.vesselName`]="{ item }">
                                        <span v-if="item.shipment">{{ item.shipment.vesselName }} {{ item.shipment.voyage }}</span>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items> -->
                <v-row justify="end">
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field outlined dense label="Search" clearable v-model="params.search"></v-text-field>
                    </v-col>
                </v-row>
                <v-data-table @click:row="viewTask" hide-default-footer disable-pagination height="60vh" :loading="loading"
                    style="cursor: pointer" fixed-header :items="tasks.data" :headers="headers" dense >
                    <template v-slot:[`item.locked`]="{ item }">
                        <span>{{ item.locked }}</span>
                    </template>
                    <template v-slot:[`item.title`]="{ item }">
                        <span>{{ item.title }}</span>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip :color="getTaskStatus(item).color" outlined small><v-icon small left>{{
                            getTaskStatus(item).icon
                        }}</v-icon>
                            {{ item.status }}</v-chip>
                    </template>
                    <template v-slot:[`item.submittedDate`]="{ item }">
                        <span>{{ formatDate(item.submittedDate) }}</span>
                    </template>
                    <template v-slot:[`item.updatedAt`]="{ item }">
                        <span v-if="item.status == 'Approved'">{{ formatDate(item.updatedAt) }}</span>
                    </template>
                    <template v-slot:[`item.submittedForInspectionByName`]="{ item }">
                        <span>{{ item.submittedForInspectionBy.firstName }} {{
                            item.submittedForInspectionBy.surname
                        }}</span>
                    </template>
                    <template v-slot:[`item.shipment.vesselName`]="{ item }">
                        <span v-if="item.shipment">{{ item.shipment.vesselName }} {{ item.shipment.voyage }}</span>
                    </template>
                    <template v-slot:[`item.bookingContainer.containerNo`]="{ item }">
                        <v-chip small v-if="item.shipment.breakBulkShipment">Breakbulk</v-chip>
                        <span v-else-if="item.bookingContainer">{{ item.bookingContainer.containerNo }}</span>
                        <v-chip small outlined v-else-if="item.containers.length > 0"> <v-icon left small>widgets</v-icon>
                            Multiple - {{ item.containers.length }}

                            <!-- {{ item.containers[0] }} <span v-if="item.containers.length>1"> + {{ item.containers.length-1 }} Others</span> -->
                        </v-chip>
                       
                    </template>


                </v-data-table>
                <v-col cols="12" class="text-center">
                    <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :pager-count="5"
                        :page-size.sync="params.limit" :page-sizes="[15, 25, 50, 100]"
                        :layout="!$vuetify.breakpoint.mobile ? 'sizes, prev, pager, next, jumper, total' : 'prev, pager, next'"
                        :total="tasks.total">
                    </el-pagination>
                </v-col>
            </v-card-text>
        </v-card>

        <v-dialog v-model="addendumDialog" fullscreen persistent>
            <ManualAddendum :thisDialog="addendumDialog" :task="task" @close="load()" />
        </v-dialog>
    </div>
</template>
<script>
import ManualAddendum from './ManualAddendum.vue'
import dateFormat from "dateformat";
export default {
    components: {
        ManualAddendum
    },
    data: () => ({
        activeTab: 'New',
        addendumDialog: false,
        fullscreen: false,
        headers: [
            {
                text: "Status",
                value: "status",
                align: "center",
                width: "150px"
            },
            // {
            //     text: "Locked",
            //     value: "locked",
            //     align: "center"
            // },
            {
                text: "Title",
                value: "title",
                align: "left",
            },
            {
                text: "File No",
                value: "shipment.shipmentFile.fileNumber",
                align: "left",
            },
            {
                text: "Container No",
                value: "bookingContainer.containerNo",
                align: "center",
            },
            // {
            //     text: "No. Containers",
            //     value: "containerCount",
            //     align: "center",
            // },
            {
                text: "Submitted By",
                value: "submittedForInspectionByName",
                sortable: false,
                align: "center",
            },
            {
                text: "Vessel",
                value: "shipment.vesselName",
                sortable: false,
                align: "center",
            },
            {
                text: "Target Region",
                value: "shipment.targetRegion",
                sortable: false,
                align: "center",
            },
            {
                text: "Target Country",
                value: "shipment.targetCountry",
                sortable: false,
                align: "center",
            },
            {
                text: "Submitted Date",
                value: "submittedDate",
                align: "center",
            },
            {
                text: "Completed Date",
                value: "updatedAt",
                align: "center",
            },
        ],
        loading: false,
        page: 1,
        params: {
            limit: 15,
            offset: 0,
            search: null,
            filter: {
                status: 'New'
            }
        },
        integrationTypes: [
            { name: "Manual Template", value: "manual" },
            { name: "REST API", value: "restapi" },
            { name: "SOAP API", value: "soap" },
            { name: "EDIFACT", value: "edifact" },
        ],
        search: null,
        tabs: ['New', 'Resubmitted', 'In Progress', 'Rejected', 'Approved', 'Cancelled','Revoked'],
        tasks: {
            data: [],
            total: 0
        },
        taskQuery: undefined,
        taskMetrics: [],
        taskTimeout: null,
        task: {},
        taskModal: false,
    }),
    watch: {
        async activeTab() {
            this.params.filter.status = this.activeTab
            await this.getTasks()
        },
        async page(value) {
            this.params.offset = (value - 1) * this.params.limit
            await this.getTasks()
        },
        'params.limit': {
            immediate: true,
            async handler() {
                this.page = 1;
                await this.getTasks()
            }
        },
        'params.search': {
            async handler() {
                this.page = 1;
                await this.getTasks()
            }
        },
    },
    created() {
        this.load()
    },
    mounted() {
    },
    methods: {
        formatDate(date) {
            let result = null;
            if (date) {
                result = dateFormat(date, "dd/mm/yyyy HH:MM");
            }
            return result;
        },
        load() {
            this.addendumDialog = false
            this.task = {}
            this.getTaskMetrics()
            this.getTasks()
        },
        getInspectionColor(status) {
            switch (status) {
                case null:
                    return 'orange'
                case 'Submitted':
                    return 'blue'
                case 'Approved':
                    return 'green'
                case 'Rejected':
                    return 'red'
                case 'Revoked':
                    return 'red'
                
            }
        },
        async getTasks() {
            // this.loading = true
            // this.tasks = await this.$API.getTasks({
            //     params: this.params
            // })
            // this.loading = false
            // if (this.loading) {
            //     clearTimeout(this.taskTimeout);
            // }
            // this.loading = true;

            // this.taskTimeout = setTimeout(async () => {
            //     this.tasks = await this.$API.getTasks({
            //         params: this.params
            //     });
            //     this.loading = false;
            // }, 600);

            if (this.taskTimeout) {
                clearTimeout(this.taskTimeout);
            }
            if (this.loading && this.taskQuery) {
                this.taskQuery.abort()
            }
            this.loading = true;
            this.taskTimeout = setTimeout(async () => {
                this.taskQuery = new AbortController();
                const signal = this.taskQuery.signal
                this.tasks = await this.$API.getTasks({
                    params: this.params,
                    signal
                });
                this.loading = false;

                console.log(this.tasks);
            }, 750);

        },
        async getTaskMetrics() {
            this.taskMetrics = await this.$API.getTaskMetrics()
        },
        getTaskStatus(task) {
            switch (task.status) {
                case "New":
                    return { name: task.status, icon: "grade", color: "orange" };
                case "Pending":
                    return { name: task.status, icon: "schedule", color: "orange" };
                case 'Resubmitted':
                    return { name: task.status, icon: "sync", color: "orange" };
                case "In Progress":
                    return { name: task.status, icon: "pending", color: "blue" };
                case "Complete":
                    return { name: task.status, icon: "task_alt", color: "green" };
                case "Success":
                    return { name: task.status, icon: "task_alt", color: "green" };
                case "Cancelled":
                    return { name: task.status, icon: "cancel", color: "grey" };
                case "Error":
                    return { name: task.status, icon: "error", color: "red" };
                case "Not Created":
                    return { name: task.status, icon: "error", color: "red" };
                case "Rejected":
                    return { name: task.status, icon: "cancel", color: "red" };
                case "Revoked":
                    return { name: task.status, icon: "cancel", color: "red" };
                case "Failed":
                    return { name: task.status, icon: "cancel", color: "red" };
                case "File Generated":
                    return { name: task.status, icon: "task_alt", color: "orange" };
                case "Draft":
                    return { name: task.status, icon: "edit_note", color: "orange" };
                case "Submitted":
                    return { name: task.status, icon: "mark_email_read", color: "blue" };
                case "Not Required":
                    return { name: task.status, icon: "close", color: "grey" };
                case "Approved":
                    return { name: task.status, icon: "fact_check", color: "green" };
                default:
                    return {
                        icon: "info",
                        color: "grey",
                        name: task.status,
                    };
            }
        },

        tabMetric(tab) {
            let find = this.taskMetrics.find(x => x.status == tab)
            return find ? find.count : null
        },
        updateDocument(doc) {
            this.$emit('updateDocument', doc)
        },
        update(doc) {
            this.$emit('update', doc)
        },
        viewTask(task) {
            this.task = task;
            this.addendumDialog = true;
        },
    },
};
</script>
