<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12" md="9">
                        <v-card flat>
                            <v-card-title>
                                Overview
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" sm="3">
                                        <v-card outlined style="border-radius: 20px; border: 1px solid orange">
                                            <v-card-text class="text-center">
                                                <v-row justify="center" align="center">
                                                    <v-col cols="5" class="text-center">
                                                        <v-icon large color="orange">grade</v-icon>
                                                    </v-col>
                                                    <v-col cols="7" class="text-center">
                                                        <span><v-chip pill outlined>{{ metrics.new }}</v-chip> <br>
                                                            New</span>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <v-col  cols="12" sm="3">
                                        <v-card outlined style="border-radius: 20px; border: 1px solid grey">
                                            <v-card-text class="text-center">
                                                <v-row justify="center" align="center">
                                                    <v-col cols="5" class="text-center">
                                                        <v-icon large color="grey">block</v-icon>
                                                    </v-col>
                                                    <v-col cols="7" class="text-center">
                                                        <span><v-chip pill outlined>{{ metrics.cancelled }}</v-chip> <br>
                                                            Cancelled</span>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <v-col  cols="12" sm="3">
                                        <v-card outlined style="border-radius: 20px; border: 1px solid green">
                                            <v-card-text class="text-center">
                                                <v-row justify="center" align="center">
                                                    <v-col cols="5" class="text-center">
                                                        <v-icon large color="green">task_alt</v-icon>
                                                    </v-col>
                                                    <v-col cols="7" class="text-center">
                                                        <span><v-chip pill outlined>{{ metrics.approved }}</v-chip> <br>
                                                            Approved</span>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>

                                    <v-col  cols="12" sm="3">
                                        <v-card outlined style="border-radius: 20px; border: 1px solid red">
                                            <v-card-text class="text-center">
                                                <v-row justify="center" align="center">
                                                    <v-col cols="5" class="text-center">
                                                        <v-icon large color="red">close</v-icon>
                                                    </v-col>
                                                    <v-col cols="7" class="text-center">
                                                        <span><v-chip pill outlined>{{ metrics.rejected }}</v-chip> <br>
                                                            Rejected</span>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-card flat>
                                            <v-card-title>
                                                Export Certification Submission Form
                                                <v-spacer></v-spacer>
                                                <v-btn class="mr-5 mb-4" icon color="blue" style="text-transform: none" @click="getChecklist()"><v-icon>refresh</v-icon></v-btn>
                                                <v-btn class="mr-5 mb-4" :disabled="checklist.length == 0" @click="downloadChecklist" text :key="downloadingKey"
                                                    :loading="downloadingChecklist" color="primary" style="text-transform: none"><v-icon>download</v-icon> Download</v-btn>
                                                <v-dialog ref="dialog" v-model="datePickerModal" :return-value.sync="date"
                                                    persistent width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field class="mb-0 pb-0"
                                                            style="max-width: 200px" v-model="date" dense outlined
                                                            label="Date" prepend-icon="calendar" readonly v-bind="attrs"
                                                            v-on="on"></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="date" color="primary" scrollable>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text color="primary" @click="datePickerModal = false">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn text color="primary" @click="$refs.dialog.save(date)">
                                                            OK
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                              
                                            </v-card-title>
                                            <v-progress-linear v-if="loadingCheckList" indeterminate
                                                color="primary"></v-progress-linear>

                                            <v-card-text>
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-left">
                                                                    Client Reference/ Container No.
                                                                </th>
                                                                <th class="text-left">
                                                                    PPECB Serial Number
                                                                </th>
                                                                <th class="text-left">
                                                                    Signed
                                                                </th>
                                                                <th class="text-left">
                                                                    Submitted
                                                                </th>
                                                                <th class="text-left">
                                                                    Completed
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(item, index) in checklist" :key="index">
                                                                <td>{{ item.reference }}</td>
                                                                <td> {{ item.serial }}</td>
                                                                <td> {{ item.passed }}</td>
                                                                <td> {{ formatDate(item.submittedTime) }}</td>
                                                                <td> {{ formatDate(item.end) }}</td>

                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                                <v-row justify="center" v-if="!loadingCheckList && checklist.length ==0">
                                                    <v-col cols="12" class="text-center">
                                                        <p>No data found</p>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <!-- <v-col cols="12" sm="6" md="3">

                        <v-card outlined flat>
                            <v-card-title>
                                <v-icon color="yellow" class="mr-1">emoji_events</v-icon> Top Performers
                            </v-card-title>
                            <v-card-text>
                                <v-list>
                                    <v-list-item v-for="(person, index) in performers" :key="index">
                                        <v-list-item-action>
                                            <v-chip>
                                                {{ person.position }}
                                            </v-chip>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ person.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ person.count }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>

                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col> -->
                    <v-col cols="12" sm="6" md="3">
                        <v-card outlined flat>
                            <v-card-title>
                                <v-icon color="blue" class="mr-1">rule</v-icon> Audit Lines
                            </v-card-title>
                            <v-card-text>
                                <v-progress-linear color="primary" v-if="loadingOffices" indeterminate></v-progress-linear>
                                <v-list dense style="max-height: 60vh;overflow-y: auto">
                                    <v-list-item v-for="(office, index) in offices" :key="index"
                                        @click="editAuditLine(office)">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ office.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="office.latestAuditLine">
                                                Last updated: {{ formatDate(office.latestAuditLine.updatedAt) }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="office.latestAuditLine">
                                            <v-chip>
                                                {{ office.latestAuditLine.month }} / {{ office.latestAuditLine.year }}
                                            </v-chip>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="auditLineModal" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
            <v-card v-if="item">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>{{ item.name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="auditLineModal = false, item = {}">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-subheader>Addendum Audit Lines <v-btn icon color="primary"
                            @click="addNewAuditLine"><v-icon>add_circle_outline</v-icon></v-btn></v-subheader>
                    <v-progress-linear color="primary" indeterminate v-if="loadingAuditLines"> </v-progress-linear>
                    <v-list v-if="!loadingAuditLines">
                        <v-list-item v-for="audit in item.auditLines" :key="audit.id" @click="editNewAuditLine(audit)">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ monthText[audit.month - 1] }} {{ audit.year }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Single Container: {{ audit.lines.join(', ') }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    Multiple Containers: {{ audit.multipleAuditLines }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    Breakbulk: {{ audit.breakBulkLine }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="item.auditLines && item.auditLines.length == 0">
                            <v-list-item-content class="text-center">
                                <span style="color: grey">No audit lines</span>
                            </v-list-item-content></v-list-item>
                    </v-list>
                </v-card-text>
            </v-card></v-dialog>

        <v-dialog v-model="newAuditLineModal" width="600px">
            <v-card v-if="auditItem">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>Manage Audit Lines</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" icon @click="saveAuditLine"
                        :loading="savingAuditLine"><v-icon>save</v-icon></v-btn>
                    <v-btn text @click="newAuditLineModal = false, auditItem = {}">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" xs="12" sm="6">
                            <v-select outlined label="Month" :items="months" v-model="auditItem.month"></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6">
                            <v-text-field outlined label="Year" v-model="auditItem.year"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>

                        <v-col cols="12" xs="12" sm="4">
                            <v-select outlined label="Single Container" :items="availableLines" multiple
                                v-model="auditItem.lines"></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="4">
                            <v-select outlined label="Multiple Containers" :items="availableLines"
                                v-model="auditItem.multipleAuditLines"></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="4">
                            <v-text-field outlined label="Breakbulk" type="number" :min="1"
                                v-model="auditItem.breakBulkLine"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import dateFormat from "dateformat";
export default {
    data: () => ({
        auditItem: {},
        auditLines: [],
        auditLineModal: false,
        checklist: [],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        datePickerModal: false,
        downloadingChecklist: false,
        downloadingKey: 1000,
        item: {},
        loadingAuditLines: false,
        loadingCheckList: false,
        loadingOffices: false,
        loadingMetrics: false,
        metrics: {},
        monthText: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        availableLines: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
        newAuditLineModal: false,
        offices: [],
        performers: [
            { name: 'Robo Danie', count: 1255, position: 1 },
            { name: 'Robo Danie', count: 1067, position: 2 },
            { name: 'Robo Danie', count: 923, position: 3 },
            { name: 'Robo Danie', count: 877, position: 4 },
            { name: 'Robo Danie', count: 643, position: 5 }
        ],
        savingAuditLine: false
    }),
    created() {
        this.getChecklist()
        this.getPPECBMetrics()
        this.getAuditLines();

    },
    watch: {
        date(){
            this.getChecklist()
        }
    },
    methods: {
        addNewAuditLine() {
            let lastAudit = {
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
                lines: [],
                multipleAuditLines: null,
                breakBulkLine: 1
            }
            // if (this.item.auditLines && this.item.auditLines.length > 0) {
            //     lastAudit = this.item.auditLines[0]
            // }
            this.auditItem = lastAudit
            this.auditItem.activityPointId = this.item.id
            this.newAuditLineModal = true
        },
        async downloadChecklist() {
            this.downloadingChecklist = true
            this.downloadingKey ++
            let file = await this.$API.generatePPECBCheckList({
                date: this.date
            });


            var hiddenElement = document.createElement("a");
            hiddenElement.href = file.file;
            hiddenElement.target = "_blank";
            hiddenElement.download = file.name+'.xlsx';
            hiddenElement.click();
            this.downloadingChecklist = false
            this.downloadingKey ++
        },
        editNewAuditLine(item) {
            this.auditItem = item
            this.auditItem.activityPointId = this.item.id
            this.newAuditLineModal = true
        },
        async editAuditLine(item) {
            this.item = item
            this.item.auditLines = []
            this.auditLineModal = true

            this.loadingAuditLines = true
            this.item.auditLines = await this.$API.getActivityPointPPECBAuditLines(this.item.id)
            this.loadingAuditLines = false
        },
        formatDate(date) {
            let result = null;
            if (date) {
                result = dateFormat(date, "dd/mm/yyyy HH:MM");
            }
            return result;
        },
        formatTime(time) {
            let result = null;
            if (time) {
                result = dateFormat(new Date(time), "HH:MM");
            }
            return result;
        },
        async getChecklist() {
            this.loadingCheckList = true
            let result = await this.$API.getPPECBChecklist({
                params: {
                    date: this.date
                }
            })
            this.checklist = result?result.certificates:[]
            this.loadingCheckList = false
        },
        async getPPECBMetrics() {
            this.loadingMetrics = true
            this.metrics = await this.$API.getPPECBMetrics()
            this.loadingMetrics = false
        },
        async getAuditLines() {
            this.loadingOffices = true
            this.offices = await this.$API.getPPECBOffices()
            this.loadingOffices = false
        },
        async saveAuditLine() {
            this.savingAuditLine = true
            if (this.auditItem.id) {
                await this.$API.updateActivityPointAuditLine(this.auditItem)
            } else {
                let result = await this.$API.createActivityPointAuditLine(this.auditItem)
                this.item.auditLines.unshift(result)
            }
            this.newAuditLineModal = false
            this.auditItem = {}
            this.savingAuditLine = false
        }
    }
}
</script>
